<template>
  <div class="container" id="container1">
    <el-row>
      <el-button type="primary" size="mini" @click="addOnOff"
        >添加触发器</el-button
      >
    </el-row>
    <div class="main">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="ID" prop="id" width="120"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="sensorCode" label="节点编号"></el-table-column>
        <el-table-column prop="expression" label="表达式"> </el-table-column>
        <el-table-column prop="value" label="数值"> </el-table-column>
        <el-table-column prop="suggest" label="建议措施"> </el-table-column>
        <el-table-column prop="state" label="状态" width="120">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="0"
              @change="changeItemSwitch(scope.row.id, scope.row.state)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.state == '在线'"
              type="view"
              size="mini"
              @click="guan(scope.row.id)"
              >关闭</el-button
            >
            <!-- <el-button v-else type="view" size="mini" @click="kai(scope.row.id)">打开</el-button> -->
            <el-button
              type="primary"
              icon="el-icon-edit-outline"
              size="mini"
              @click="editItem(scope.row.id)"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-delete-solid"
              size="mini"
              @click="del(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagingBox">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="title" class="trace11" :visible.sync="isAdd" width="60%">
      <el-form
        :model="adds"
        class="nocodeForm"
        label-width="100px"
        style="width: 45%"
      >
        <el-form-item label="名称">
          <el-input v-model="adds.name"></el-input>
        </el-form-item>
        <el-form-item label="节点编号">
          <el-input v-model="adds.sensorCode"></el-input>
        </el-form-item>
        <el-form-item label="设备密码">
          <el-input v-model="adds.password"></el-input>
        </el-form-item>
        <el-form-item label="表达式">
          <el-select v-model="adds.expression" placeholder="请选择表达式">
            <el-option label=">" value=">"></el-option>
            <el-option label="=" value="="></el-option>
            <el-option label="<" value="<"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数值">
          <el-input v-model="adds.value" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="建议措施">
          <el-input
            type="textarea"
            resize="none"
            :rows="5"
            v-model="adds.suggest"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="noCodeBox">
        <div class="base-item" style="margin-left: 0px">
          <span id="switchspan" style="text-align: left">继电器开关</span>
          <div>
            <input
              style="width: 150px; float: left; height: 36px; outline: none"
              v-model="adds.toDevice"
              type="text"
              name="command"
              value=""
              autocomplete="off"
              id="command"
              placeholder="请输入联动设备号"
            />
            <p
              class="el-icon-search"
              id="nocodeSearchBtn"
              @click="searchDeviceOncode()"
            ></p>
          </div>
        </div>
        <el-table
          class="nocodeTable"
          ref="nocodeTable"
          :data="ncodeTableData"
          tooltip-effect="dark"
        >
          <el-table-column prop="sensorCode" label="节点编号"></el-table-column>
          <el-table-column prop="state" label="继电器开关">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.state"
                class="nCodeItem"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="isAdd = false">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: true,
      isAdd: false,
      currentPage2: 1,
      tableData: [],
      total: 0,
      page: 1,
      size: 10,
      ncodeTableData: [],
      ncodeTotal: [],
      multipleSelection: [],
      arrId: [],
      adds: {
        name: "",
        password: "",
        expression: "",
        value: "",
        sensorCode: "",
        id: 0,
        toDevice: "",
        command: "",
      },
      editId: "",
      title: "添加组态",
      formLabelWidth: "120px",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      this.$get("/conditionConfig/conditionConfigList", {
        page: this.page,
        limit: this.size,
      }).then((res) => {
        if (res.data.state == "success") {
          let m = res.data.datas;
          // for(let i = 0; i < m.length; i++){
          //     if(m[i].state == 1){
          //         m[i].state = true
          //     } else {
          //         m[i].state = true
          //     }
          // }
          this.tableData = m;
          this.total = res.data.count;
        }
      });
    },
    searchDeviceOncode() {
      this.$get("/conditionConfig/getSensorList", {
        id: this.adds.id,
        page: 1,
        limit: 10,
        ncode: this.adds.toDevice,
      }).then((res) => {
        if (res.data.state == "success") {
          var m = res.data.datas;
          //console.log(m);
          //console.log("%%%%%%%%%%%%%");
          this.ncodeTableData = m;
          this.ncodeTotal = res.data.size;
        }
      });
    },
    changeItemSwitch(id, state) {
      this.$post("/conditionConfig/updateState", {
        state: state,
        id: id,
      }).then((res) => {
        if (res.data.state == "success") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    // 添加触发器
    addOnOff() {
      this.isAdd = true;
      this.editId = "";
      this.adds = {
        name: "",
        password: "",
        expression: "",
        value: "",
        sensorCode: "",
        id: 0,
        toDevice: "",
        command: "",
      };
    },
    // 编辑弹框
    editItem(id) {
      this.isAdd = true;
      this.editId = id;
      this.title = "编辑触发器";
      let m = this.tableData;
      for (let i = 0; i < m.length; i++) {
        if (id == m[i].id) {
          this.adds = m[i];
        }
      }
    },
    // 提交添加触发器
    submitAdd() {
      let url = "/conditionConfig/saveCondition";

      if (this.adds.id == 0) {
        var msg = "添加成功";
      } else {
        var msg = "修改成功";
      }
      var $nCodeItem = $(".nCodeItem");
      for (var x = 1; x < $nCodeItem.length - 1; x++) {
        if (x < 10) {
          if ($nCodeItem.eq(x).hasClass("is-checked")) {
            // this.adds.command = this.adds.command + "0" + x + "01";
            this.adds.command = "0" + x + "01";
          } else {
            // this.adds.command = this.adds.command + "0" + x + "00";
            this.adds.command = "0" + x + "00";
          }
        } else {
          if ($nCodeItem.eq(x).hasClass("is-checked")) {
            // this.adds.command = this.adds.command + x + "01";
            this.adds.command = x + "01";
          } else {
            // this.adds.command = this.adds.command + x + "00";
            this.adds.command = x + "00";
          }
        }
      }
      var data = this.adds;

      // data.suggest = ''

      this.$post(url, data).then((res) => {
        if (res.data.state == "success") {
          this.$message({
            message: msg,
            type: "success",
          });
          this.getList();
          this.isAdd = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.isAdd = false;
        }
      });
    },
    // 删除
    del(id) {
      this.$confirm("确定要删除报警触发器吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post("/conditionConfig/delCondition", {
          id: id,
        }).then((res) => {
          if (res.data.state == "success") {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getList();
          } else {
            this.$message({
              message: "删除失败",
              type: "error",
            });
          }
        });
      });
    },
    handleSizeChange(size) {
      this.size = size;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 表格复选框事件
    handleSelectionChange(val) {
      this.arrId = [];
      for (let i = 0; i < val.length; i++) {
        this.arrId.push(val[i].id);
      }
      this.multipleSelection = val;
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
.container {
  width: 100%;
  height: 88vh;
  padding: 1vh;
  box-sizing: border-box;
  overflow: hidden;
}

.main {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
  margin-top: 2.5vh;
  background: #fff;
}

.el-pagination {
  margin-top: 2vh;
  width: 84vw;
  position: absolute;
  bottom: 4vh;
}

.main .el-table {
  width: 100%;
  height: 70vh;
  overflow-y: auto;
}

.el-table--enable-row-transition .el-table__body td {
  text-align: center;
}

.nocodeTable {
  clear: both;
}

.nocodeTable:after {
  content: "";
  display: block;
  clear: both;
}

.nocodeForm {
  display: inline-block;
}

.noCodeBox {
  float: right;
  width: 50%;
}

#switchspan {
  height: 38px;
  line-height: 38px;
  text-align: left;
  float: left;
  margin-right: 8px;
}

#nocodeSearchBtn {
  width: 42px;
  background: linear-gradient(182deg, #68dab6 0%, #66c1b8 100%);
  color: #fff;
  text-align: center;
  height: 36px;
  line-height: 36px;
  margin-left: 5px;
  cursor: pointer;
}
</style>
<style>
#container1 .el-form-item__label {
  width: 15%;
}

#container1 .el-form-item__content {
  display: inline-block !important;
  width: 65%;
  margin-left: 0 !important;
}

.el-button--view {
  background: #85ce61;
  color: #fff;
  border: 0;
}

.el-button--view:hover {
  background: #85ce61;
  color: #fff;
  border: 0;
}

.container .el-table td,
.container .el-table th {
  text-align: center !important;
}
</style>
